<template>
  <div>
    <TableFilters :title-count="sourceObject.count" :queries="queries" :loading="isDateLoaded"/>
    <TableLayout
      :loading="isDateLoaded"
      to="userGroups"
      :table-data="sourceObject.results"
      :header="header"
      :source-object="sourceObject"
    />
  </div>
</template>

<script>
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsUserGroup from "@/helpers/tableCols/UserGroup";
export default {
  name: "ControllersUsersGroups",
  data() {
    return {
      queries: query.groups.filter(el => el.type === "search")
    };
  },
  components: { TableLayout, TableFilters },
  computed: {
    header() {
      return tableColsUserGroup.filter(
        el =>
          el.text == "ID" ||
          el.text == "Название" ||
          el.text == "Пользователи" ||
          el.value == "controllers" ||
          el.value == "tariff_name" ||
          el.value == "price"
      );
    },
    sourceObject() {
      return this.$store.getters.getPageGroupsList;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: { ...this.urlQuery.query, controllers__id: this.urlQuery.id }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchPageGroupsList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query,
            controllers__id: this.urlQuery.id
            // type: this.type
          }
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchPageGroupsList").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  }
  // beforeRouteLeave(to, from, next) {
  //   this.$store.dispatch("clearGroupsPage").then(() => next());
  // }
};
</script>

<style scoped></style>
r
